.header-section {
    background-color: #007bff;
    color: #fff;
    min-height: 80vh;
  }
  
  .sectionsearch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    width: 100%;
  }
  
  .containersearch {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 1200px;
    width: 100%;
  }
  
  .columnsearch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .heading5 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .heading {
    font-size: 2.5rem;
    margin: 10px 0;
    text-align: left;
    color: #007bff;
  }
  
  .text {
    font-size: 1rem;
    text-align: left;
    line-height: 1.6;
    color: #555;
  }
  
  .image {
    max-width: 100%;
    max-height: 400px;
    width: auto;
    height: auto;
    object-fit: cover;
  }
  
  @media (max-width: 768px) {
    .containersearch {
      grid-template-columns: 1fr;
      padding: 20px;
    }
  
    .heading {
      font-size: 2rem;
      text-align: center;
    }
  
    .text {
      font-size: 0.9rem;
      text-align: center;
    }
    
    .sectionsearch {
      padding: 20px 0;
    }
  
    .columnsearch {
      align-items: center;
    }
  }
  