.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.bg-video{
  position: relative;
}
.video{
  position: absolute
}
.App-header {
  background-color: #282c34;
  background: url('https://www.youtube.com/watch?v=n-T_J9PisuI');
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.form-m{
  margin: 0 3em;
}
.m-tt{
  margin:0 1em ;
}

@media (min-width: 180px) and (max-width: 780px) {
  .m-tt{
    margin: 1em 1em;
  }
}

.wrap{
  flex-wrap: wrap;
}

.op-img-top{
  opacity: 0.2;
}
.op-img-top:hover{
  opacity: 1;
  transition: .3s ease-in-out;
}
.card{
  border: none;
}

.slick-slider {
  width: 100%;
  overflow: hidden;
}
.slick-list {
  position: relative;
  display: block !important;
  width: 100%;
}
.slick-slide div {
  width: auto;
  margin-right: 10px;
}
.slick-slide img {
  width: calc(100% - 10px) !important;
}



/* For the demo */
.slick-prev,
.slick-next {
  z-index: 999 !important;
}
.slick-prev {
  left: 25px !important;
}
.slick-next {
  right: 25px !important;
}
