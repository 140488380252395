/* Blog.css */
body {
    background: linear-gradient(135deg, #f8f9fa, #e9ecef); /* Subtle gradient background for the whole page */
    font-family: 'Roboto', sans-serif; /* Custom font for the body */
    margin: 0;
    padding: 0;
  }
  
  .container-fluid {
    background-color: #007bff; /* Primary background color */
    color: #fff;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .container-fluid h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Light shadow for text */
  }
  
  .container-fluid h5 {
    font-size: 1rem;
    margin-top: 0;
  }
  
  .blog-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items at the top */
    padding: 0 20px; /* Padding on both sides */
    margin: 0 auto; /* Centering the container */
    max-width: 1200px; /* Maximum width for larger screens */
    animation: fadeIn 1s ease-in-out; /* Fade-in animation for the container */
  }
  
  .blog-content {
    width: 100%;
    max-width: 1200px; /* Maximum width for the blog content */
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff; /* Background color for readability */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow with more depth */
    animation: slideInLeft 1s ease-out; /* Slide-in left animation */
  }
  
  .blog-content h1, .blog-content h2 {
    color: #333; /* Dark color for headings */
  }
  
  .blog-content h1 {
    font-size: 2.5rem; /* Larger font size for the main heading */
    margin-bottom: 20px;
    font-weight: 700; /* Bold text */
    animation: fadeInUp 1s ease-in-out; /* Fade-in up animation */
  }
  
  .blog-content h2 {
    font-size: 1.75rem; /* Medium font size for subheadings */
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600; /* Semi-bold text */
    animation: fadeInUp 1s ease-in-out; /* Fade-in up animation */
  }
  
  .blog-content p {
    color: #555; /* Lighter color for text */
    line-height: 1.6; /* Improved readability */
    margin-bottom: 20px;
    font-size: 1rem; /* Standard font size for paragraphs */
    transition: color 0.3s ease; /* Smooth color transition on hover */
  }
  
  .blog-content p:hover {
    color: #007bff; /* Change color on hover */
  }
  
  .blog-content a {
    color: #007bff; /* Color for links */
    text-decoration: none; /* Remove underline */
    font-weight: 500; /* Slightly bold text */
    transition: color 0.3s ease, text-shadow 0.3s ease; /* Smooth transition */
  }
  
  .blog-content a:hover {
    text-decoration: underline; /* Underline on hover */
    color: #0056b3; /* Darker color on hover */
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Light shadow on hover */
  }
  
  /* Animation Definitions */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideInLeft {
    from { transform: translateX(-100%); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @media (max-width: 768px) {
    .blog-content {
      padding: 15px;
    }
  
    .blog-content h1 {
      font-size: 2rem; /* Smaller font size for smaller screens */
    }
  
    .blog-content h2 {
      font-size: 1.5rem; /* Smaller font size for smaller screens */
    }
  }