.services-section {
    padding: 40px 0;
    background: #f9f9f9;
  }
  
  .containersoftdev {
    width: 90%;
    margin: 0 auto;
  }
  
  .heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .heading h2 {
    font-size: 50px;
    margin: 0;
  }
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .service-item {
    width: 30%;
    margin-bottom: 20px;
  }
  
  .service-link {
    text-decoration: none;
    color: inherit;
  }
  
  .service-box {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }

  .service-box:hover {
    background-color: #e0e0e0; /* Darker shade of the box color */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }
  
  .service-title {
    font-size: 30px;
    margin: 10px 0;
  }
  
  .service-description {
    font-size: 14px;
    line-height: 2;
  }
  
  @media (max-width: 768px) {
    .service-item {
      width: 100%;
    }
  }