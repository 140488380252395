/* html, body {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */

  
  /* .container, .row {
    max-width: 100%; 
    padding-left: 20px;
  } */
  
  .card {
    width: 100%; /* Ensure card doesn't overflow */
  }
  
  .card-body, .card-header {
    padding: 20px; /* Adjust padding */ 
  }
  
  ul.list-unstyled {
    padding-left: 0; /* Remove extra padding */
    margin-right: 0; /* Remove extra margin */
  }
  
  .img-fluid {
    max-width: 100%; /* Ensure images are responsive */
    height: auto;
    display: block; /* Prevent image overflow */
  }
  
  h2 {
    margin: 0; /* Adjust heading margin */
  }
  
  a.btn {
    white-space: nowrap; /* Prevent button text from overflowing */
    border-radius: 15px;
  }

  @media (min-width: 180px) and (max-width: 780px) {
    .card{
        margin: 0;
    } 
  }

  .anim-bg{
    /* background: url('../images/animationback.be1d4c334012208eced2.jpg'); */
    background: mintcream;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-car{
    background-color: black;
    background: black;
  }
  .btn-bg-cerd{
    background: url('../images/vector.png');
    background-repeat: no-repeat;
    background-size: cover;
   
  }
  .justify-content-center{
    padding-top: 1em;
  }