  .navbar-phone-list {
    background-color: #c6c3c3; /* White background */
    color: #000;            /* Black text color */
    border-radius: 8px;     /* Rounded corners */
    padding: 8px 12px;      /* Padding inside the list item */
    list-style: none;       /* Remove default bullet points */
    margin: 0;              /* Remove default margins */
    display: inline-block;  /* Ensure it doesn't take the full width */
    margin-right: 90px;
    /* margin-top: 10px; */
  }
  
  .navbar-phone-list li {
    margin: 0;              /* Remove any default margins */
  }
  
  .navbar-visible {
    transition: top 0.3s;
  }
  
  .navbar-hidden {
    transition: top 0.3s;
    top: -60px; /* Adjust based on your navbar height */
  }
  
  /* Media query for small screens */
@media (max-width: 768px) {
  .navbar-phone-list {
    margin-top: 10px; /* Add top margin on small screens */
  }
}

.navbar-visible {
  transition: top 0.3s;
}

.navbar-hidden {
  transition: top 0.3s;
  top: -60px; /* Adjust based on your navbar height */
}