.slider-container {
    margin: 0 120px; /* Adjust margin as needed */
    background: none;
  }
  
  .slide {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
  
  .slide img {
    height: 110px; 
    width: 60px;
    object-fit: contain; /* Ensure the image covers the area */
  }
  
  /* Additional responsive styling */
  @media (max-width: 1024px) {
    .slider-container {
      margin: 0 4px;
    }
  }
  
  @media (max-width: 600px) {
    .slider-container {
      margin: 0 10px;
    }
  }
  
  @media (max-width: 480px) {
    .slider-container {
      margin: 0 5px;
      .slide img {
        /* height: 100px; */
        width: 55px;  /* Set the width to half of the height */
        object-fit: cover; /* Ensure the image covers the area */
      }
      .slide img {
        height: 110px; 
        width: 60px;
        object-fit: contain; /* Ensure the image covers the area */
      }
    }
  }
  