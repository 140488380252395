.aicontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 30px;
  }
  
  .aitext-container, .aiimage-container {
    flex: 1;
    min-width: 300px;
  }
  
  .aiheading {
    font-size: 3em;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  
  .aiheading span {
    display: block;
    font-size: 1.5em;
    margin-top: 10px;
  }
  
  .airesponsive-image {
    max-width: 100%;
    height: auto;
    animation: aifadeIn 2s ease-in-out;
  }
  
  @keyframes aifadeIn {
    0% { opacity: 0; transform: translateX(-20px); }
    100% { opacity: 1; transform: translateX(0); }
  }
  
  @media (max-width: 768px) {
    .aicontainer {
      flex-direction: column;
    }
  
    .aiheading {
      font-size: 1.5em;
    }
  
    .aiheading span {
      font-size: 1.2em;
    }
  }
  

      /* heading css  */
  .aisubheading-container {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center; /* Center-aligns the heading */
  }
  
  .aisubheading {
    font-size: 2.5em;
    margin: 0; /* Removes default margin */
  }
  
  .aisubheading-highlight {
    color: #0582CA; /* Sets the color of the highlighted text */
  }
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
    .aisubheading {
      font-size: 1.5em; /* Adjusts font size for smaller screens */
    }
  }
  
  /* ai services css  */

  /* Container for the entire section */
.services-container {
    display: flex;
    justify-content: center; /* Center items horizontally */
    padding: 20px;
    background-color: #f9f9f9; /* Background color */
  }
  
  /* Inner container to handle layout and spacing of items */
  .services-inner {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto the next line if needed */
    gap: 20px; /* Space between items */
    max-width: 1200px; /* Limit maximum width */
    width: 100%; /* Full width of the container */
    justify-content: center; /* Center items horizontally */
  }
  
  /* Style for each service item */
  .service-item {
    flex: 1 1 calc(50% - 20px); /* Flex-grow and flex-basis for responsiveness, adjusting for gap */
    min-width: 300px; /* Minimum width to prevent shrinking too much */
    padding: 20px;
    background-color: #fff; /* Background color */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  }
  
  /* Heading style */
  .service-heading {
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  
  /* Highlighted text style */
  .highlight {
    color: #0582CA; /* Highlight color */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .service-item {
      flex: 1 1 100%; /* Full width for each item */
      min-width: 100%; /* Ensure items take full width */
    }
  
    .service-heading {
      font-size: 1.5em;
    }
  }
  

  /* Container for the entire section */
.features-container {
    display: flex;
    justify-content: center; /* Center items horizontally */
    padding: 20px;
    background-color: #f9f9f9; /* Background color */
  }
  
  /* Inner container to handle layout and spacing of items */
  .features-inner {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto the next line if needed */
    gap: 20px; /* Space between items */
    max-width: 1200px; /* Limit maximum width */
    width: 100%; /* Full width of the container */
    justify-content: center; /* Center items horizontally */
  }
  
  /* Style for each feature item */
  .feature-item {
    flex: 1 1 calc(50% - 20px); /* Flex-grow and flex-basis for responsiveness, adjusting for gap */
    min-width: 300px; /* Minimum width to prevent shrinking too much */
    padding: 20px;
    background-color: #fff; /* Background color */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  }
  
  /* Heading style */
  .feature-heading {
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  
  /* Highlighted text style */
  .highlight {
    color: #0582CA; /* Highlight color */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 768px) {
    .feature-item {
      flex: 1 1 100%; /* Full width for each item */
      min-width: 100%; /* Ensure items take full width */
    }
  
    .feature-heading {
      font-size: 1.5em;
    }
  }

  /* key benifits css  */

  /* Container for the heading section */
  .key-benefits-container {
    display: flex;
    justify-content: center; /* Center the heading horizontally */
    padding: 20px;
    background-color: #f9f9f9; /* Background color */
  }
  
  /* Heading style */
  .key-benefits-heading {
    font-size: 2em; /* Adjust font size */
    text-align: center; /* Center align the text */
    color: #333; /* Text color */
  }
  
  /* Highlighted text style */
  .highlight {
    color: #0582CA; /* Highlight color */
  }
  

  /* key benifits services css */

 /* Container for the section */
.key-benefits-section {
    padding: 20px;
    background-color: #f9f9f9; /* Background color */
  }
  
  /* Ensure the container is centered and spaced correctly */
  .containerr {
    display: flex;

    flex-direction: column;
    align-items: center;
  }
  
  /* Row for the columns */
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center columns horizontally */
  }
  
  /* Each column */
  .column {
    flex: 1 1 33%; /* Grow to fill available space, with a base width of 33% */
    padding: 10px;
    box-sizing: border-box; /* Ensure padding is included in the width */
  }
  
  /* FAQ item styling */
  .faq-item {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: left;
  }
  
  /* FAQ header styling */
  .faq-header {
    margin-bottom: 10px;
  }
  
  /* FAQ title styling */
  .faq-title {
    font-size: 1.5em;
    margin: 0;
    color: #333; /* Text color */
  }
  
  /* FAQ body styling */
  .faq-body {
    font-size: 1em;
    color: #666; /* Text color */
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .column {
      flex: 1 1 100%; /* Stack columns vertically on smaller screens */
    }
  }

  /* Transform Your Business CSS */


 /* General Styles */
.your-section {
    padding: 20px; /* Adjust padding as needed */
  }
  
  .containerr {
    display: flex;
    align-items: center; /* Center content vertically in the container */
    justify-content: space-between; /* Space between text and button */
    gap: 20px; /* Adjust spacing between columns */
  }
  
  .text-column {
    flex: 1;
    display: flex;
    flex-direction: column; /* Stack heading and paragraph vertically */
  }
  
  .title {
    font-size: 24px; /* Adjust font size as needed */
    margin-bottom: 10px; /* Adjust margin as needed */
  }
  
  .description {
    font-size: 16px; /* Adjust font size as needed */
    color: #666; /* Adjust text color as needed */
  }
  
  .button-column {
    display: flex;
    justify-content: center; /* Center button horizontally */
    align-items: center; /* Center button vertically */
  }
  
  .contact-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font size as needed */
    background-color: #007bff; /* Adjust background color as needed */
    color: #fff;
    text-decoration: none;
    border-radius: 4px; /* Adjust border radius as needed */
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: #0056b3; /* Adjust hover background color as needed */
  }
  
  .fas {
    margin-left: 8px; /* Space between text and icon */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .containerr {
      flex-direction: column; /* Stack columns vertically on smaller screens */
      align-items: center; /* Center items horizontally */
    }
  
    .button-column {
      margin-top: 20px; /* Space between text and button on smaller screens */
      justify-content: center; /* Center button horizontally */
    }
  }
  
  @media (max-width: 480px) {
    .contact-button {
      font-size: 14px; /* Adjust font size for smaller screens */
      padding: 8px 16px; /* Adjust padding for smaller screens */
    }
  }
  

/* ML DL Chatbot css  */



.containerr {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
}

.card h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  padding-bottom: 10px;
}

.card p {
  font-size: 1em;
  line-height: 1.5;
}

.airesponsive-image{
    max-width: 80%;
}

.ai-wid{
    width: 70%;
}

@media (min-width: 180px) and (max-width: 1300px){

    .ai-wid{
        width: 100%;
    }
    .airesponsive-image{
        max-width: 100%;
    }
}