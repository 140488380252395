/* src/components/PreviousProjects.css */
.projects-container {
    padding: 2em;
    text-align: center;
    background-color: #f9f9f9; /* Adjust background color as needed */
  }
  
  .projects-container h2 {
    margin-bottom: 1em;
    font-size: 2em;
  }
  
  a:link {
    text-decoration: none;
  }

  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
  }
  
  .project-card {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    cursor: pointer;
    width: 350px; /* Adjust based on your requirement */
  }
  
  .project-card:hover {
    transform: translateY(-10px);
  }
  
  .project-image {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }
  
  .project-title {
    padding: 1em;
    font-size: 1.2em;
    background-color: blue;
    color: white;
  }