/* companycontactus.css */

/* General body styling */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4; /* Light gray background */
}

/* Styling for the main container */
.companycontactus-container-fluid {
  background-color: #007bff; /* Blue background */
  color: #fff;
  min-height: 80vh; /* Full height of viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 1.2s ease-in-out;
}

/* Text center styling */
.companycontactus-text-center {
  margin-top: 5%;
}

/* Contact info styling */
.companycontactus-contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #e3f2fd; /* Light blue background */
}

.companycontactus-info-box {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ffffff; /* White background */
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1s ease-in-out forwards;
}

/* Different animations for each info-box */
.companycontactus-info-box:nth-child(1) {
  animation: fadeInLeft 1s ease-in-out forwards;
}

.companycontactus-info-box:nth-child(2) {
  animation: fadeIn 1s ease-in-out forwards;
}

.companycontactus-info-box:nth-child(3) {
  animation: fadeInRight 1s ease-in-out forwards;
}

/* Hover effects for info-box */
.companycontactus-info-box:hover {
  transform: scale(1.05); /* Slightly enlarge */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow */
  background-color: #f1f1f1; /* Light gray background on hover */
}

/* Icon styling */
.companycontactus-icon {
  color: #007bff; /* Blue color for icons */
  margin-bottom: 10px;
}

/* Contact form styling */
.companycontactus-contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f0f8ff, #e6e6e6); /* Gradient background */
  padding: 40px;
  min-height: 60vh; /* Minimum height for form container */
  animation: fadeIn 1.2s ease-in-out;
}

.companycontactus-contact-form {
  max-width: 500px; /* Maximum width of the form */
  width: 100%;
  background: #fff; /* White background */
  padding: 30px;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Deep shadow */
  transition: transform 0.3s, box-shadow 0.3s; /* Animation on hover */
}

.companycontactus-contact-form:hover {
  transform: scale(1.02); /* Slight zoom effect on hover */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
}

.companycontactus-contact-form h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #333; /* Darker text color */
  font-size: 2rem; /* Larger font size */
  font-weight: 700; /* Bold text */
}

.companycontactus-form-group {
  margin-bottom: 20px;
}

.companycontactus-form-group label {
  display: block;
  margin-bottom: 8px;
  color: #555; /* Slightly lighter text color */
}

.companycontactus-form-group input,
.companycontactus-form-group textarea {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid #ddd; /* Border color */
  border-radius: 6px; /* Rounded corners for inputs */
  font-size: 16px; /* Larger font size */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition */
}

.companycontactus-form-group input:focus,
.companycontactus-form-group textarea:focus {
  border-color: #007bff; /* Border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3); /* Shadow on focus */
  outline: none; /* Remove default outline */
}

.companycontactus-form-group textarea {
  height: 150px; /* Increased height for textarea */
}

.contactusbutton {
  display: block;
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(135deg, #007bff, #00c6ff); /* Gradient background */
  color: white;
  font-size: 18px;
  font-weight: 600; /* Bold text */
  cursor: pointer;
  transition: background 0.3s, transform 0.3s; /* Animation on hover */
}

.contactusbutton:hover {
  background: linear-gradient(135deg, #0056b3, #0099ff); /* Darker gradient on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.contactusbutton:focus {
  outline: none; /* Remove default outline */
}

/* Keyframe animations */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
      opacity: 0;
      transform: translateX(-30px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
      opacity: 0;
      transform: translateX(30px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .companycontactus-contact-info {
      flex-direction: column;
  }

  .companycontactus-contact-form-container {
      padding: 20px;
  }
}


.popup-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-content {
  text-align: center;
}

.popup-icon {
  color: #007bff;
  margin-bottom: 15px;
}

.popup-content button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #0056b3;
}
