/* src/components/ImageSlider.css */
.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
  margin: auto;
}

.slider-wrapper {
  width: 90%;
  max-width: 1200px; /* Adjust based on your requirement */
  overflow: hidden;
}

.slider-image {
  width: 100%;
  height: auto;
  display: block;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.nav-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.nav-btn:nth-of-type(1) {
  left: 10px;
}

.nav-btn:nth-of-type(2) {
  right: 10px;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
  .nav-btn:nth-of-type(1) {
    left: 40px;
  }

  .nav-btn:nth-of-type(2) {
    right: 40px;
  }
}

@media (min-width: 1024px) {
  .nav-btn:nth-of-type(1) {
    left: 50px;
  }

  .nav-btn:nth-of-type(2) {
    right: 50px;
  }
}

@media (min-width: 1440px) {
  .nav-btn:nth-of-type(1) {
    left: 160px;
  }

  .nav-btn:nth-of-type(2) {
    right: 160px;
  }
}