/* logo and uiux css  */

.Appportfolio {
    text-align: center;
    padding: 20px;
  }
  
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
  }
  
  .logo-item {
    margin: 10px;
  }
  
  .logo-item img {
    width: 100%;
    max-width: 350px;
    height: auto;
    object-fit: contain;
    transition-duration: 0.3s;
    transition-property: transform, opacity;
  }
  
  /* Keyframes for sliding animations */
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInFromCenter {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Apply animations to the logos */
  .logo-item:nth-child(1) img {
    animation: slideInFromLeft 1s ease-in-out;
  }
  
  .logo-item:nth-child(2) img {
    animation: slideInFromCenter 1s ease-in-out;
  }
  
  .logo-item:nth-child(3) img {
    animation: slideInFromRight 1s ease-in-out;
  }
  
  /* Hover effect */
  .logo-item img:hover {
    transform: scale(1.1);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .logo-item img {
      max-width: 400px;
    }
  }
  
  @media (max-width: 480px) {
    .logo-item img {
      max-width: 400px;
    }
  }