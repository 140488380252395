
/* content writting css  */

/* new content css  */

.online-business-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  text-align: left;
}

.containerc {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
}

.columnc {
  flex: 1 1 50%;
  padding: 20px;
  box-sizing: border-box;
}

.image-container img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s;
}

.text-container {
  margin: auto;
}

.button-container {
  margin-top: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffa500;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #ff4500;
}

.fadeInLeft {
  animation: fadeInLeft 1s;
}

.fadeInRight {
  animation: fadeInRight 1s;
}

.fadeInUp {
  animation: fadeInUp 1s;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .column {
    flex: 1 1 100%;
  }
  .columnc {
    flex: 1 1 100%;
    padding: 20px;
    box-sizing: border-box;
  }
}

/* SEO CSS  */
.seo-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  text-align: left;
}

.seotext{
  color: blueviolet;
}
.container {
  width: 100%;
  max-width: 1200px;
}

.inner-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.column {
  flex: 1 1 50%;
  padding: 20px;
  box-sizing: border-box;
}

.image-container img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s;
}

.text-container {
  margin: auto;
}

.fadeInLeft {
  animation: fadeInLeft 1s;
}

.fadeInRight {
  animation: fadeInRight 1s;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .column {
    flex: 1 1 100%;
  }
}