*{
  margin: 0;
  padding: 0;
}
/* styles.css */
a{
  text-decoration: none;
}
.content {
  padding: 20px;
}
/* .containerr{
  padding: 20px 1/;
} */
.image-container {
  position: relative;
  overflow: hidden;
}

/* Example animation classes using animate.css */
.animate__animated {
  animation-duration: 1s;
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/* sencond section */

/* styles.css */

.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  /* border: 1px solid #ccc; */
}

.text-center {
  text-align: center;
}

/* Example animation classes using animate.css */
.animate__animated {
  animation-duration: 1s;
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn-get-started {
  background-color: lightblue;
  border-radius: 50px;
  padding: 10px 20px;
  color: black; /* Adjust text color as needed */
  font-weight: bold;
  text-decoration: none; /* Remove underline from anchor */
  display: inline-block; /* Ensures it behaves like a button */
}
.custom-gradient-section {
  background: linear-gradient(to right, #ff416c, #ff4b2b);
  padding: 80px 0; /* Adjust padding as needed */
}

.custom-section {
  padding: 80px 0; /* Adjust padding as needed */
}

.btn-rounded {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 50px;
  padding: 10px 20px;
  color: #000000; /* Adjust text color */
  font-weight: bold;
  text-decoration: none; /* Remove underline */
  transition: background-color 0.3s ease;
}

.btn-rounded:hover {
  background-color: rgba(255, 255, 255, 0.5); /* Lighten background on hover */
  color: #000000; /* Adjust text color on hover */
}
.img-fluid{
  border-radius: 20px;
}
.faq-item{
  margin-top: 2em;
}
.m-t{
  margin-top: 3em;
}
.m-t-1{
  margin-top: 1em;
}
.card-bottom{
  width: 195px;
  height: 158px;
}
.image-margin{
  margin-bottom: 0.8em;
    margin-right: 0.5em;
}
.m-l{
  margin-left: 1em;
}

.m-t{
  padding-top: 6em;
}
.shamri{
  margin: -8em 0;
}
.shamri{
  @media (min-width: 180px) and (max-width: 780px) {
  margin: -12em 0;
  }
}

.tt{
  @media (min-width: 180px) and (max-width: 780px) {
    text-align: center;
}
}

.m-ttt{
  @media (min-width: 180px) and (max-width: 780px) {
    margin-top: 3em;
}
}

.slick-slider {
  width: 100%;
  overflow: hidden;
}
.slick-list {
  position: relative;
  display: block !important;
  width: 100%;
}
.slick-slide div {
  width: auto;
  margin-right: 10px;
}
.slick-slide img {
  width: calc(100% - 10px) !important;
}



/* For the demo */
.slick-prev,
.slick-next {
  z-index: 999 !important;
}
.slick-prev {
  left: 25px !important;
}
.slick-next {
  right: 25px !important;
}

.stty{
  width: 230px;
}

@media (max-width: 768px) {
  /* Styles for screens less than 768px wide */
  .header h1 {
    font-size: 2rem;
  }
}

.landing-page {
  overflow-y: scroll; /* Enable vertical scrolling */
  height: 100vh; /* Set height to 100% viewport height */
}


.yellows{
  background: #ffba00;
  border: 1px solid white;
  padding: 15px  60px;
  border-radius: 20px;
  color: white;
}

.yellows:hover{
  background: white;
  border: 1px solid #ffba00;
  padding: 15px  60px;
  border-radius: 20px;
  color: #ffba00;
}

/* FormComponent.css */

.my-form {
  background-color: #fff;
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.my-form h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.my-form h3 {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.my-form input,
.my-form textarea {
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.my-form button {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.my-form button:hover {
  background-color: #530;
}
.home-font{
  font-size:50px;
  font-weight: 900;
  font-family: "Poppins", sans-serif;

}
.home-form-heading{
  font-size: 40px;
  color: rgb(22, 89, 153);
  font-weight: 900;
  font-family: "Poppins", sans-serif;

}
.m-button{
  margin-left: 2em;
}
.hero-button{
  padding: 15px 40px;
  border: 1px solid white;
  background:rgb(0, 0, 0) ;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 700;
  color:white;
  text-shadow: rgb(8, 97, 100);
  box-shadow: yellowgreen;
}
.hero-button:hover{
  padding: 15px 40px;
  border: 1px solid rgb(8, 97, 100);
  background: rgb(84, 82, 82);
  border-radius: 20px;
  font-size: 15px;
  font-weight: 700;
  color:rgb(255, 255, 255);
  /* font-family: "Ga Maamli", sans-serif; */
  
}
.m-t{
  margin-top: 5em;
}
.button-left{
  margin-left: 1em;
}
.form-sub-heading{
  font-size: 25px;
}
.chat-us{
  font-family: "Bona Nova SC", serif;
}
.right-chat-us-wrapper{
  background: white;
  width: 22%;
  border: 1px transparent;
  border-radius: 6em 0 0 6em;
  padding-top: .5em;
  margin-left: 5em;
  position: fixed;
  right: -22em;
  transition: .4s ease-in-out;
}
.right-chat-us-wrapper:hover{
  right: -8em;
  transition: .4s ease-in-out;
}

/* css home start */
/* css home start */

.bg-section{
  /* background: url('../images/background.gif'); */
  background: url('../images/backgroundimageforhomepage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
  width: 100%;
  margin-top: -8em;
}
.anim-img{
  /* background: url('../images/hell.gif'); */
  background-color: #FFFEFA;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh;
  width: 100%; */
}
.wh{
  color: rgb(0, 0, 0);
}
.con{
  /* background: linear-gradient(#020024, #211fa1 25%, #00d4ff 100%); */
  /* background: white; */
  width: 90%;
  height: 99%;
  overflow: hidden;
}
.card-for-wid{
  /* width: 350px; */
  overflow: hidden;
  
}
.card-main-wrap{
  max-width: 100%;
  margin-top: 1em;
}

.f-btn{
  padding: 15px 50px;
  background-color: blue;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: 1em 52em;
}
.f-btn:hover{
  padding: 15px 50px;
  background-color: white;
  color: blue;
  border: 1px solid blue;
  border-radius: 10px;
}
@media (min-width: 180px) and (max-width: 780px) {
  .f-btn{
    padding: 15px 50px;
    background-color: blue;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    margin: 1em 06em;
  }
  .home-form-heading{
    font-size: 20px;
  }
  .home-font{
    font-size: 18px;
  }
  .m-button{
    margin-left: 0em;
    margin-top: .7em;
  }
  .hero-button{
    padding: 10px 20px;
    border: 1px solid white;
    background:rgb(22, 89, 153) ;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 300;
    color:white;
  }
  .button-left{
    margin-left: 1em;
  }
  .button-main-wrap{
    justify-content: center;
  }
  p{
    font-size: 13px;
  }
  .form-sub-heading{
    font-size: 18px;
  }
  .start-btn-f{
    font-size: 15px;
  }
  .form-control{
    font-size: 13px;
  }
  .right-chat-us-wrapper{
    display: block;
  }
  .bg-section{
    background: url('../images/backgroundimageforhomepage.jpg');
    /* background: url('../images/background.gif'); */
    background-repeat: no-repeat;
    background-size: cover;
    /* height: 180vh; */
    width: 100vw;
    margin-top: -8em;
    
  }
  .right-content{
    margin-top: 1em;
  }
}

.card{
  margin-left: 20px;
}

@media (min-width: 180px) and (max-width: 1100px){
  .forquery{
    flex-flow: column;
  }

}

.btnn{
  padding:5px 30px; 
  background:blue; 
  font-weight:400; 
  font-size:22px;
  border-radius:10px;
}

.bttn{
  color:white;}
.m-t{
  margin-top: 3em;
}
  @media (min-width: 859px) and (max-width: 1200px) {

    .row {
      flex-direction: row;
    }
    .home-font{
      font-size: 28px;
    }
    li{
      font-size: 15px;
    }
    p{
      font-size: 15px;
    }
    .m-t{
      margin-top: 0.1em;
    }
    .bg-section{
      margin-top: 0;
    }
    .home-form-heading{
      font-size: 22px;
    }
    .form-sub-heading{
      font-size: 12px;
    }
    .my-form input, .my-form textarea{
      padding: 5px;
      margin-bottom: 10px;
    }
    .hero-button{
      padding: 7px  20px;
    }
    .m-t{
      margin-top: 0em;
    }
  }

  @media (min-width: 1px) and (max-width: 1370px) {

    .row {
      flex-direction: row;
    }
    .home-font{
      font-size: 28px;
    }
    li{
      font-size: 15px;
    }
    p{
      font-size: 15px;
    }
    .m-t{
      margin-top: 0.1em;
    }
    .bg-section{
      margin-top: -75px;
    }
    .home-form-heading{
      font-size: 22px;
    }
    .form-sub-heading{
      font-size: 12px;
    }
    .my-form input, .my-form textarea{
      padding: 5px;
      margin-bottom: 10px;
    }
    .hero-button{
      padding: 7px  20px;
    }
    .m-t{
      margin-top: 0em;
    }
  }

  

  /* out team css */

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1001;
  }
  
  .popup-icon {
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .popup-content button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background-color: #0056b3;
  }
  
  