/* packages cards css  */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f4;
  }
  
  .packages {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 40px;
    justify-items: center;
    background-color: #e8eaf6; /* Changed background color */
    border-radius: 15px; /* Added border radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added box shadow */
  }
  
  .package-card {
    /* background: linear-gradient(145deg, #6a89cc, #b8e994); */
    background: steelblue;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .package-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .package-card h2 {
    margin-bottom: 10px;
    font-size: 1.8em;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
  }
  
  .package-card p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #fff;
    /* color: black; */
  }
  
  /* Additional animation for styling */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .package-card {
    animation: fadeIn 0.6s ease-in-out;
  }
  
  /* Importing Google Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;500&display=swap');
  
  
  
  /* combo package css */
  
  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f4;
  }
  
  .combo-packages {
    text-align: center;
    padding: 40px;
    /* background-color: #e3f2fd; */
  }
  
  .combo-packages h1 {
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #0277bd; 
    animation: fadeIn 1s ease-in-out;
  }
  
  .combo-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .combo-card {
    background: linear-gradient(145deg, #81d4fa, #4fc3f7); /* Gradient background */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    width: 100%;
  }
  
  .combo-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .combo-card h2 {
    margin-bottom: 15px;
    font-size: 1.8em;
    /* color: #fff; */
    color: black;
    font-family: 'Montserrat', sans-serif;
    animation: fadeIn 1.2s ease-in-out;
  }
  
  .combo-card p {
    font-size: 1.1em;
    line-height: 1.6;
    /* color: #fff; */
    color: black;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  /* Additional animation for styling */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive styles */
  @media (min-width: 768px) {
    .combo-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .combo-container {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-template-areas:
        "combo-card1 combo-card2"
        "combo-card3 combo-card4"
        "combo-card5 combo-card5";
    }
    
    .combo-card:nth-child(1) {
      grid-area: combo-card1;
    }
  
    .combo-card:nth-child(2) {
      grid-area: combo-card2;
    }
  
    .combo-card:nth-child(3) {
      grid-area: combo-card3;
    }
  
    .combo-card:nth-child(4) {
      grid-area: combo-card4;
    }
  
    .combo-card:nth-child(5) {
      grid-area: combo-card5;
    }
  }
  
  /* Importing Google Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;500&display=swap');