.chat-icons-wrapper {
    position: fixed;
    top: 150px;
    right: -15px;
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between the two icons */
    z-index: 1000;
}

.chat-icon-container {
    position: relative;
}

.chat-with-us-wrap {
    display: flex;
    align-items: center;
    padding-left: 1em;
    background-color: rgb(255, 255, 255); /* Add background color */
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    cursor: pointer;
    transition: width 0.1s ease-in-out;
    width: 50px; /* Initial width */
    height: 40px; /* Initial height */
}

.chat-icon-container:hover .chat-with-us-wrap {
    width: 170px; /* Expanded width on hover */
}

.img-chat img {
    width: 35px;
    transition: transform 0.3s ease-in-out;
}

.chat-us-heading {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.chat-icon-container:hover .chat-us-heading {
    opacity: 1;
}

.chat-us {
    margin-left: .5em;
    white-space: nowrap;
    font-size: 16px;
    color: black;
    text-decoration: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .chat-with-us-wrap {
        width: 50px; /* Adjust width for smaller screens */
        height: 50px; /* Adjust height for smaller screens */
    }
    
    .chat-icon-container:hover .chat-with-us-wrap {
        width: 150px; /* Adjust expanded width for smaller screens */
    }
    
    .chat-us {
        font-size: 14px; /* Adjust font size for smaller screens */
    }
    
    .chat-us-heading {
        font-size: 14px; /* Adjust font size for headings on smaller screens */
    }
}

@media (max-width: 480px) {
    .chat-icons-wrapper {
        display: none; /* Hide both icons on very small screens */
    }
}
